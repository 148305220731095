// EmergencyOptionsComponent.js
import React, { useState } from 'react';
import { Box, Text, Button, Image, VStack, Grid } from '@chakra-ui/react';
import locale from '../locale/locale.json';

// Importa las imágenes (asegúrate de que las rutas sean correctas)
import redCrossIcon from '../assets/red-cross.png';
import nineOneOneIcon from '../assets/911-call.png';
import firefightersIcon from '../assets/firefighter.png';
import policeIcon from '../assets/police.png';

function EmergencyOptionsComponent({ language, userSelectedYes, onOptionSelected }) {
    const [selectedOption, setSelectedOption] = useState(null);

    const optionsYes = [
        { name: locale.red_cross[language], icon: redCrossIcon },
        { name: '911', icon: nineOneOneIcon, phone: 'tel:911' },
        { name: locale.firefighters[language], icon: firefightersIcon },
        { name: locale.police[language], icon: policeIcon },
    ];

    const optionsNo = [
        { name: '911', icon: nineOneOneIcon, phone: 'tel:911' },
    ];

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        onOptionSelected(option);
        if (option.phone) {
            window.location.href = option.phone;
        }
    };

    const options = userSelectedYes ? optionsYes : optionsNo;

    // Determina el mensaje a mostrar
    const message = userSelectedYes
        ? locale.select_emergency_option[language]
        : locale.call_to[language];

    // Determina el número de columnas en la cuadrícula
    const columns = options.length === 4 ? 2 : 1;

    return (
        <Box>
            <Text>{message}</Text>
            <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={4} mt={2}>
                {options.map((option, index) => (
                    <Button
                        key={index}
                        variant="ghost"
                        isDisabled={!!selectedOption}
                        onClick={() => handleOptionClick(option)}
                    >
                        <VStack>
                            <Image src={option.icon} alt={option.name} boxSize="25px" objectFit="contain" />
                            <Text fontSize="sm" textAlign="center">{option.name}</Text>
                        </VStack>
                    </Button>
                ))}
            </Grid>
        </Box>
    );
}

export default EmergencyOptionsComponent;
