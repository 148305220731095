import {
    Box,
    Heading,
    Text,
    VStack,
    Badge,
    List,
    ListItem
} from '@chakra-ui/react';

import locale from '../locale/locale.json';

function MedicalCard({ patient, language }) {

    // Function to calculate age from birthDay
    function calculateAge(birthDate) {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();

        // Adjust age if birth date hasn't occurred yet this year
        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
        ) {
            age--;
        }
        return age;
    }

    const age = calculateAge(patient.birthDay);

    return (
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} boxShadow="md" width="100%">

            <Heading align="center" color="red" as="h2" size="lg" mb={3}>{locale.medical_card[language]}</Heading>
            <Text fontSize="md" fontWeight="bold" mb={1}>{locale.name[language]}:</Text>
            <Heading as="h3" align="left" size="lg" mt={0} mb={2}>{patient.name}</Heading>
            <Text fontSize="md">{locale.age[language]}: {age}</Text>
            <Text fontSize="md">{locale.gender[language]}: {patient.gender}</Text>
            <Text fontSize="md">{locale.blood_type[language]}: {patient.bloodType}</Text>
            <VStack align="stretch" mt={4}>
                <Text fontSize="md" fontWeight="bold">{locale.medical_conditions[language]}:</Text>
                <List spacing={1}>
                    {patient.diseases.map((disease, index) => (
                        <ListItem key={index}>{disease}</ListItem>
                    ))}
                </List>
            </VStack>
            <VStack align="stretch" mt={4}>
                <Text fontSize="md" fontWeight="bold">{locale.allergies[language]}:</Text>
                {patient.hasAlergy ? (
                    <List spacing={1}>
                        {patient.alergies.map((allergy, index) => (
                            <ListItem key={index}>{allergy}</ListItem>
                        ))}
                    </List>
                ) : (
                    <Text>{locale.no_alergies[language]}</Text>
                )}
            </VStack>

            <VStack align="stretch" mt={4}>
                <Text fontSize="md" fontWeight="bold">{locale.medications[language]}:</Text>
                {patient.onMedication ? (
                    <List spacing={1}>
                        {patient.medications.map((medication, index) => (
                            <ListItem key={index}>{medication}</ListItem>
                        ))}
                    </List>
                ) : (
                    <Text>{locale.no_medications[language]}</Text>
                )}
            </VStack>

            <VStack spacing={4} mt={4} >
                <Badge colorScheme={patient.hasInsurance ? "green" : "red"}>
                    {patient.hasInsurance ? `${locale.insurance[language]}` : `${locale.no_insurance[language]}`}
                </Badge>
                <Badge colorScheme={patient.hasIntlCoverage ? "blue" : "gray"}>
                    {patient.hasIntlCoverage ? `${locale.international_insurance[language]}` : `${locale.no_international_insurance[language]}`}
                </Badge>
            </VStack>

            <Text mt={4} fontWeight="bold">{locale.insurance_company[language]}:</Text>
            <Text>{patient.insurances}</Text>
        </Box>
    );
}

export default MedicalCard;
